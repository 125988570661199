import * as S from './MonthPicker.styled';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ELEMENT_IDS } from '@ntb-sport/constants';
import IconButton from '../../../../chakra/IconButton/IconButton';
import { Text } from '../../../../chakra';
interface MonthPickerProps {
  calendars: any;
  month: number;
  year: number;
  getBackProps: any;
  getForwardProps: any;
  translations: {
    previousDay: string;
    nextDay: string;
    previousMonth: string;
    nextMonth: string;
    months: string[];
    weekdays: string[];
    today: string;
  };
}

export const MonthPicker = ({
  calendars,
  month,
  year,
  translations,
  getBackProps,
  getForwardProps,
}: MonthPickerProps) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        justifyItems: 'center',
        alignItems: 'center',
        gridColumnGap: 'var(--ntb-space-4)',
        marginBottom: 'var(--ntb-space-4)',
      }}
    >
      <IconButton
        {...getBackProps({ calendars })}
        data-element-id={ELEMENT_IDS.BUTTON}
        aria-label={translations.previousMonth}
        size="sm"
        icon={<ChevronLeftIcon />}
        borderWidth="1px"
        borderColor="var(--border-color)"
        backgroundColor="var(--background-color)"
        color="var(--font-color)"
        _hover={{
          bg: 'var(--background-color__hover)',
          color: 'var(--font-color__hover)',
        }}
        _active={{
          bg: 'var(--background-color__active)',
          color: 'var(--font-color__active)',
        }}
      />
      <Text fontSize="sm">
        {translations.months[month]} {year}
      </Text>
      <IconButton
        {...getForwardProps({ calendars })}
        data-element-id={ELEMENT_IDS.BUTTON}
        aria-label={translations.nextMonth}
        size="sm"
        icon={<ChevronRightIcon />}
        borderWidth="1px"
        borderColor="var(--border-color)"
        backgroundColor="var(--background-color)"
        color="var(--font-color)"
        _hover={{
          bg: 'var(--background-color__hover)',
          color: 'var(--font-color__hover)',
        }}
        _active={{
          bg: 'var(--background-color__active)',
          color: 'var(--font-color__active)',
        }}
      />
    </div>
  );
};
