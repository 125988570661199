import { Props as DayzedProps, useDayzed } from 'dayzed';
import { Days } from './Days/Days';
import { MonthPicker } from './MonthPicker/MonthPicker';
import { Weekdays } from './Weekdays/Weekdays';
import * as S from './Calendar.styled';

import { ELEMENT_IDS } from '@ntb-sport/constants';

import { Flex, Button } from '../../../chakra';

interface CalendarProps extends DayzedProps {
  daysWithEventsInMonth?: any;
  isOpen: boolean;
  translations: {
    previousDay: string;
    nextDay: string;
    previousMonth: string;
    nextMonth: string;
    months: string[];
    weekdays: string[];
    today: string;
  };
  isLoadingDaysWithEventsInMonth?: boolean;
  onClickToday: () => void;
}

export const Calendar = ({
  isOpen,
  daysWithEventsInMonth,
  onClickToday,
  translations,
  isLoadingDaysWithEventsInMonth,
  ...rest
}: CalendarProps) => {
  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    ...rest,
  });

  return calendars?.map((calendar) => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '52px',
          left: '0',
          width: '100%',
          height: '100vh',
          backgroundColor: 'var(--background-color)',
          color: 'var(--font-color)',
          zIndex: 99,
          padding: 'var(--ntb-space-2)',
        }}
        id="calendar"
        data-element-id={ELEMENT_IDS.CONTAINER}
        hidden={!isOpen}
        key={`${calendar.month}${calendar.year}`}
      >
        <MonthPicker
          calendars={calendars}
          month={calendar.month}
          year={calendar.year}
          getBackProps={getBackProps}
          getForwardProps={getForwardProps}
          translations={translations}
        />
        <Weekdays
          weekdays={translations?.weekdays}
          year={calendar.year}
          month={calendar.month}
        />
        <Days
          weeks={calendar.weeks}
          year={calendar.year}
          month={calendar.month}
          daysWithEventsInMonth={daysWithEventsInMonth}
          isLoadingDaysWithEventsInMonth={isLoadingDaysWithEventsInMonth}
          getDateProps={getDateProps}
        />
        <Flex p={4} justifyContent="center">
          <Button
            data-element-id={ELEMENT_IDS.BUTTON}
            size="sm"
            onClick={onClickToday}
            borderWidth="1px"
            backgroundColor="var(--background-color)"
            borderColor="var(--border-color)"
            color="var(--font-color)"
            _hover={{
              backgroundColor: 'var(--background-color__hover)',
              color: 'var(--font-color__hover)',
            }}
            _active={{
              backgroundColor: 'var(--background-color__active)',
              color: 'var(--font-color__active)',
            }}
          >
            {translations?.today}
          </Button>
        </Flex>
      </div>
    );
  });
};
