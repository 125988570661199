import * as S from './Weekdays.styled';

interface WeekdaysProps {
  weekdays: string[];
  month: number;
  year: number;
}

export const Weekdays = ({ weekdays, month, year }: WeekdaysProps) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        justifyItems: 'center',
        gridColumnGap: 'var(--ntb-space-2)',
        marginBottom: 'var(--ntb-space-2)',
      }}
    >
      {weekdays.map((weekday) => (
        <div
          style={{
            fontSize: 'var(--ntb-fontSizes-xs)',
            fontWeight: 'var(--ntb-fontWeights-normal)',
          }}
          key={`${month}${year}${weekday}`}
        >
          {weekday}
        </div>
      ))}
    </div>
  );
};
